import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import '../styles/main.css';
// import TwitterIcon from './Icons/Twitter';
// import GithubIcon from './Icons/Github';
// import RssIcon from './Icons/RSS';
import Logo from './Logo';
import SocialIcons from './SocialIcons';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <link
          href="https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <div className="min-h-screen flex flex-col">
        <header>
          <div className="mx-auto container flex justify-between mb-5 mt-4">
            <div className="leading-none">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <SocialIcons />
          </div>
        </header>

        <main className="mx-auto container flex-grow text-justify content">
          {children}
        </main>

        <footer className="border-t border-gray-300 pt-10 mt-10 text-lg">
          <div className="mx-auto container flex flex-wrap pb-24 desktop:pb-32">
            <div className="w-1/2 desktop:w-1/6 leading-none">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="w-1/2 desktop:w-2/6">
              <h5 className="uppercase mt-0 text-sm desktop:text-sm">Social</h5>
              <SocialIcons />
            </div>
            <div className="w-full desktop:w-3/6">
              <h5 className="uppercase mt-12 desktop:mt-0 text-sm desktop:text-sm">
                About
              </h5>
              <div className="text-justify text-base desktop:text-lg">
                I am a London based web developer currently focused on
                JavaScript, Node and React. Get in touch if you wish to compare
                eslint configs or debate the deadliest enemy in videogame
                history (spoiler alert: it was the first goomba).
              </div>
            </div>
          </div>
          <div className="mx-auto container pb-3 text-center text-sm desktop:text-sm">
            &copy; 39digits 2021
          </div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
