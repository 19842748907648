import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
// import Image from 'gatsby-image';
// import BackgroundImage from 'gatsby-background-image';
// import { useStaticQuery } from 'gatsby';
import { DiscussionEmbed } from 'disqus-react';

import Layout from '../components/Layout';
import SEOMetadata from '../components/SEO';
import TwitterShareButton from '../components/ShareIcons/Twitter';
import FacebookShareButton from '../components/ShareIcons/Facebook';
import RedditShareButton from '../components/ShareIcons/Reddit';
import HackerNewsShareButton from '../components/ShareIcons/HackerNews';
import BuyMeACoffee from '../components/BuyMeACoffee';

export const pageQuery = graphql`
  query MDXQuery($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        date(formatString: "DD MMMM YYYY")
        description
        disqusId
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

const PostTemplate = ({ location, data }) => {
  // Could also destructure above using { data: { mdx: post } }
  const post = data.mdx;
  // const isHeader = post.frontmatter.featuredImage;
  // const isHeader = false;
  // let featuredImage;
  // if (isHeader) {
  //   featuredImage = post.frontmatter.featuredImage.childImageSharp.fluid;
  // }
  // const { header } = useStaticQuery(graphql`
  //   query {
  //     header: file(relativePath: { eq: "husky_prettier_banner.jpg" }) {
  //       sharp: childImageSharp {
  //         fluid {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //   }
  // `);

  const disqusConfig = {
    shortname: '39digits',
    config: {
      identifier: post.frontmatter.disqusId || post.frontmatter.slug,
      title: post.frontmatter.title,
      url: location.href,
    },
  };

  return (
    <Layout location={location}>
      <SEOMetadata
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={
          post.frontmatter.socialImage
            ? post.frontmatter.socialImage.childImageSharp.gatsbyImageData
                .images.fallback.src
            : false
        }
      />
      {/* {isHeader && (
        <div className="full-width">
          <Image fluid={featuredImage} />
        </div>
      )} */}
      <div className="pt-5">
        <header className="desktop:px-12">
          <h1 className="mb-3 mt-0 desktop:mt-10 desktop:text-4xl text-left">
            {post.frontmatter.title}
          </h1>
          <div className="text-gray-600 text-right">
            {post.frontmatter.date}
          </div>
        </header>
        {/* {isHeader && (
          <BackgroundImage
            style={{ zIndex: '-1' }}
            className="full-width mt-12"
            fluid={featuredImage}
            backgroundColor={`#040e18`}
          ></BackgroundImage>
        )} */}
        <div className="markdown bg-white px-1 desktop:px-12 pt-0 desktop:pt-5 desktop:text-xl">
          {/* <SocialIcons /> */}
          <MDXRenderer>{post.body}</MDXRenderer>
          <div className="mt-16 mb-12 text-lg text-left flex flex-wrap">
            <div className="w-full desktop:w-1/2 leading-none text-center desktop:text-left">
              <h5 className="uppercase mt-0 mb-2 text-sm desktop:text-sm">
                Share this article
              </h5>
              <TwitterShareButton
                link={location.href}
                title={post.frontmatter.title}
              />
              <FacebookShareButton link={location.href} />
              <RedditShareButton
                link={location.href}
                title={post.frontmatter.title}
              />
              <HackerNewsShareButton
                link={location.href}
                title={post.frontmatter.title}
              />
            </div>
            <div className="w-full desktop:w-1/2 leading-none mt-4 desktop:m-0 text-center desktop:text-left">
              <h5 className="uppercase mt-0 mb-4 text-sm desktop:text-sm">
                Was this post helpful?
              </h5>
              <BuyMeACoffee />
            </div>
          </div>
          <DiscussionEmbed {...disqusConfig} />
        </div>
      </div>
    </Layout>
  );
};

export default PostTemplate;
