import React from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEOMetadata from '../components/SEO';
import Layout from '../components/Layout';
import usePublishedPosts from '../hooks/use-publishedposts';

const HomePage = (props) => {
  const posts = usePublishedPosts();

  const { banner } = useStaticQuery(graphql`
    {
      banner: file(relativePath: { eq: "banner.jpeg" }) {
        sharp: childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout location={props.location}>
      <SEOMetadata title="All blog articles" />
      <div className="banner">
        <GatsbyImage
          image={banner.sharp.gatsbyImageData}
          alt="Homepage banner"
        />
      </div>
      <div className="markdown bg-white px-1 desktop:px-12 pt-0 desktop:pt-5 desktop:text-xl">
        <h1 className="desktop:text-4xl">Articles</h1>
        {posts.map((post) => (
          <div className="mb-10" key={post.title}>
            <p className="text-gray-600 text-base mb-0">{post.date}</p>
            <Link to={`/${post.slug}`} className="font-bold">
              <h2 className="mt-0 mb-1">{post.title}</h2>
            </Link>
            <p className="text-base mt-0">{post.description}</p>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default HomePage;
