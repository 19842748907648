import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
// import Image from 'gatsby-image';
import { StaticImage } from "gatsby-plugin-image"

const Logo = () => {
  return (
    <div className="inline-block text-center">
      {/* <Image fixed={logo.sharp.fixed} /> */}
      <StaticImage src="../assets/images/39digits.png" placeholder="none" backgroundColor="white" width={50} alt="39digits logo" />
      {/* letter-spacing: 0.2em;
    margin-left: 2px; */}
      <div className="tracking-ultimate font-bold text-sm desktop:text-base">
        digits
      </div>
    </div>
  );
};

export default Logo;
