/**
 * Twitter SVG Icon
 */
import React from 'react';
import PropTypes from 'prop-types';

function Twitter({ title, widthClass, heightClass, colour, extraClasses }) {
  const SVGData =
    'M23.954 4.569a10 10 0 0 1-2.825.775 4.958 4.958 0 0 0 2.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 0 0-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 0 0-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 0 1-2.228-.616v.061a4.923 4.923 0 0 0 3.946 4.827 4.996 4.996 0 0 1-2.212.085 4.937 4.937 0 0 0 4.604 3.417 9.868 9.868 0 0 1-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 0 0 7.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 0 0 2.46-2.548l-.047-.02z';

  return (
    <svg
      className={`fill-current inline-block ${widthClass} ${heightClass} ${extraClasses}`}
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path fill={colour} d={SVGData} />
    </svg>
  );
}

Twitter.defaultProps = {
  title: `Twitter icon`,
  // We use Tailwind so will pass down h-x classes instead of explicitly setting widths
  widthClass: `w-5`,
  // Same with heights and using Tailwind class names
  heightClass: `h-5`,
  colour: ``,
  extraClasses: ``,
};

Twitter.propTypes = {
  title: PropTypes.string.isRequired,
  widthClass: PropTypes.string.isRequired,
  heightClass: PropTypes.string.isRequired,
  colour: PropTypes.string,
  extraClasses: PropTypes.string,
};

export default Twitter;
