import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import SEOMetadata from '../components/SEO';

const FourOhFour = (props) => {
  return (
    <Layout location={props.location}>
      <SEOMetadata title="I am Error 404" />
      <div className="markdown bg-white px-1 desktop:px-12 pt-0 desktop:pt-5 desktop:text-xl">
        <h1 className="desktop:text-6xl">I am Error 404</h1>
        <div className="flex flex-wrap">
          <div className="w-full desktop:w-2/3 pr-6">
            <p className="mb-8">Did you follow a bad Link?</p>
            <p>Perhaps one of these pages would be of interest?</p>
            <div className="mb-10">
              <p className="text-gray-600 text-base mb-0">06 August 2017</p>
              <p className="mt-0">
                <Link
                  to="/configure-prettier-and-eslint-in-visual-studio-code"
                  className="font-bold"
                >
                  Configure Prettier and ESLint in Visual Studio Code
                </Link>
              </p>
              <p className="text-base mt-0">
                Learn how to setup Prettier and ESLint for use on any JavaScript
                project inside the excellent Visual Studio Code.
              </p>
            </div>
            <div className="mb-10">
              <p className="text-gray-600 text-base mb-0">06 August 2017</p>
              <p className="mt-0">
                <Link
                  to="/configure-prettier-and-eslint-in-visual-studio-code"
                  className="font-bold"
                >
                  Migrate G Suite account to a Personal Google Account
                </Link>
              </p>
              <p className="text-base mt-0">
                Google hasn't made it easy, but it is possible to migrate your G
                Suite account over to a Personal Gmail account.
              </p>
            </div>
            {/* <p>
              Perhaps one of these pages would be of interest.
              <ul>
                <li>
                  <Link to="">
                    Configure Prettier and ESLint in Visual Studio Code
                  </Link>
                </li>
                <li>
                  <Link to="">
                    Migrate G Suite account to a Personal Google Account
                  </Link>
                </li>
              </ul>
            </p> */}
          </div>
          <div className="w-full desktop:w-1/3">
            <StaticImage
              src="../assets/images/404.png"
              placeholder="none"
              backgroundColor="white"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FourOhFour;
