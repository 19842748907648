import React from 'react';

import logo from './bmac-logo.svg';
import './bmac.css';

const BuyMeACoffee = () => {
  return (
    <a
      className="bmc-button"
      rel="noopener noreferrer"
      target="_blank"
      href="https://www.buymeacoffee.com/39digits"
    >
      <img src={logo} alt="Buy me a coffee"></img>
      <span style={{ marginLeft: '5px' }}>Buy me a coffee</span>
    </a>
  );
};

export default BuyMeACoffee;
