import React from 'react';

import TwitterIcon from './Icons/Twitter';
import GithubIcon from './Icons/Github';
import RssIcon from './Icons/RSS';

const SocialIcons = () => {
  return (
    <div className="flex justify-start items-center text-gray-500 no-underline">
      <a
        className="flex items-center hover:text-gray-700 mr-4"
        href="https://twitter.com/39digits"
        aria-label="Twitter account of 39digits"
      >
        {/* #1DA1F2 */}
        <TwitterIcon />
      </a>
      <a
        className="flex items-center text-gray-500 hover:text-gray-700 mr-4"
        href="https://github.com/39digits"
        aria-label="Github account of 39digits"
      >
        <GithubIcon />
      </a>
      <a
        className="flex items-center text-gray-500 hover:text-gray-700 mr-4"
        href="/rss.xml"
        aria-label="RSS feed for the site"
      >
        <RssIcon />
      </a>
    </div>
  );
};

export default SocialIcons;
