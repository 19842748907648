/**
 * RSS SVG Icon
 */

import React from 'react';
import PropTypes from 'prop-types';

function RSS({ title, widthClass, heightClass, colour, extraClasses }) {
  const SVGData =
    'M19.199 24C19.199 13.467 10.533 4.8 0 4.8V0c13.165 0 24 10.835 24 24h-4.801zM3.291 17.415a3.3 3.3 0 0 1 3.293 3.295A3.303 3.303 0 0 1 3.283 24C1.47 24 0 22.526 0 20.71s1.475-3.294 3.291-3.295zM15.909 24h-4.665c0-6.169-5.075-11.245-11.244-11.245V8.09c8.727 0 15.909 7.184 15.909 15.91z';

  return (
    <svg
      className={`fill-current inline-block ${widthClass} ${heightClass} ${extraClasses}`}
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path fill={colour} d={SVGData} />
    </svg>
  );
}

RSS.defaultProps = {
  title: `RSS icon`,
  // We use Tailwind so will pass down h-x classes instead of explicitly setting widths
  widthClass: `w-5`,
  // Same with heights and using Tailwind class names
  heightClass: `h-5`,
  colour: ``,
  extraClasses: ``,
};

RSS.propTypes = {
  title: PropTypes.string.isRequired,
  widthClass: PropTypes.string.isRequired,
  heightClass: PropTypes.string.isRequired,
  colour: PropTypes.string,
  extraClasses: PropTypes.string,
};

export default RSS;
