import { graphql, useStaticQuery } from 'gatsby';

const usePublishedPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { frontmatter: { status: { eq: "published" } } }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        nodes {
          frontmatter {
            title
            slug
            date(formatString: "DD MMMM YYYY")
            description
          }
        }
      }
    }
  `);

  return data.allMdx.nodes.map((post) => ({
    title: post.frontmatter.title,
    slug: post.frontmatter.slug,
    date: post.frontmatter.date,
    description: post.frontmatter.description,
  }));
};

export default usePublishedPosts;
